.interfaceFrame {
    position: absolute;
    max-width: 1600px !important;
    max-height: 900px !important;
    & > div {
        border-radius: 10px;
    }
    img {
        height: 100%;
        width: 100%;
    }
}

.interfacesContainer {
    position: relative;
}

.firstInterface {
    width: 45vw;
    height: 26.5vw;
    transform: translateX(12vw) translateY(-2vw) perspective(500px) rotateY(-15deg) rotateX(4deg) skewY(3deg) skewX(-3deg);
}

.secondInterface {
    transform: translateX(6vw) translateY(6vw) perspective(500px) rotateY(-15deg) rotateX(4deg) skewY(6deg) skewX(-3deg);
}