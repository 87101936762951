.interfaceWave {
    transform: scaleX(-1) scaleY(-1);
    margin-top: -1.5px;
}
.cellaLogo {
    width: 300px;
    height: auto;
}

.circleContactIcon { 
    box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
}