.containerLeft {
    display: flex;
}

.logoSmall {
    width: 91px;
    height: auto;
}

.logoRegular {
    width: 186px;
    height: auto;
}

.containerMobile {
    min-width: 320px;
}

.mobileMenuAnchor {
    > span {
        width: 100%;
        justify-content: space-between;
    }
}

.mobileMenuAnchorHr {
    border-bottom: 1px dotted #101010;
}