.ilustrationsContainer {
    position: relative;
}

.heroInterfaceMobileIlustration {
    height: auto;
    width: 40%;
    position: absolute;
    left: 20%;
    top: 8vw;

    @media (max-width: 1020px) {
        top: 14vw;
    }

    @media (min-width: 1200px) {
        top: 7rem;
    }
}

.heroInterfaceIlustration {
    height: auto;
    width: 70%;
    right: 0;
    position: absolute;
}

.containerIlustrations {
    width: 40vw;
    height: 30vw;
    position: relative;
    margin: auto;

    @media (max-width: 1020px) {
        width: 70vw;
        height: 60vw;
    }
}

.containerIlustrationsMobile {
    width: 100%;
    height: auto;
    position: relative;
    margin: auto;
}


.doubleWaveAnimationContainer {
    width: 50%;
    height: 60%;
    top: 50%;
    position: absolute;
    right: -30px;
}

.explodeLinesAnimationContainer {
    width: 50%;
    height: 60%;
    position: absolute;
    left: -10%;
    top: -30%;
}