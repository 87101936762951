.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (max-width: 768px) {
        max-width: 85vw !important;
    }

    @media (min-width: 768px) {
        max-width: 728px !important;
    }

    @media (min-width: 1020px) {
        max-width: 1000px !important;
    }

    @media (min-width: 1200px) {
        max-width: 1160px !important;
    }

    @media (min-width: 1440px) {
        max-width: 1240px !important;
    }

}

.containerNoMargin {
    width: 100%;

    @media (max-width: 768px) {
        max-width: 85vw !important;
    }

    @media (min-width: 768px) {
        max-width: 728px !important;
    }

    @media (min-width: 1020px) {
        max-width: 1000px !important;
    }

    @media (min-width: 1200px) {
        max-width: 1160px !important;
    }

    @media (min-width: 1440px) {
        max-width: 1240px !important;
    }
}