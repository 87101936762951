.heroBackground {
    position: relative;
    overflow: hidden;
    background-position: 0 calc(100% + .5px) !important;
    background-size: 101%, cover !important;
    background-repeat: no-repeat !important;
}

.interfaceArtifacts {
    position: absolute;
    width: 100%;
    bottom: -0.5px;
    z-index: 1;
}

.interfaceArtifact_waves {
    width: 1440px;
}


.interfaceComponents {
    z-index: 2;
}