@font-face {
    font-family: 'Lexend Deca';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(./assets/fonts/LexendDeca/LexendDeca-Regular.woff) format("woff"),
        url(./assets/fonts/LexendDeca/LexendDeca-Regular.woff2) format("woff2");
}

@font-face {
    font-family: 'Lexend Deca';
    font-weight: 300;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'light';
    src: url(./assets/fonts/LexendDeca/LexendDeca-Light.woff) format("woff"),
        url(./assets/fonts/LexendDeca/LexendDeca-Light.woff2) format("woff2");
}

@font-face {
    font-family: 'Lexend Deca';
    font-weight: 600;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'SemiBold';
    src: url(./assets/fonts/LexendDeca/LexendDeca-SemiBold.woff) format("woff"),
        url(./assets/fonts/LexendDeca/LexendDeca-SemiBold.woff2) format("woff2");
}

@font-face {
    font-family: 'Lexend Deca';
    font-weight: 700;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Bold';
    src: url(./assets/fonts/LexendDeca/LexendDeca-Bold.woff) format("woff"),
        url(./assets/fonts/LexendDeca/LexendDeca-Bold.woff2) format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(./assets/fonts/Roboto/Roboto-Regular.woff) format("woff"),
        url(./assets/fonts/Roboto/Roboto-Regular.woff2) format("woff2");
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Bold';
    src: url(./assets/fonts/Roboto/Roboto-Bold.woff) format("woff"),
        url(./assets/fonts/Roboto/Roboto-Bold.woff2) format("woff2");
}

::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
    background-clip: padding-box;
}

::-webkit-scrollbar-track {
    display: none;
    background-clip: padding-box;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    width: 6px;

    background-color: rgba(32, 32, 32, 0.3);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid rgba(32, 32, 32, 0.1);
    /* creates padding around scroll thumb */
}

.leaflet-container {
    height: 100%;
}