.buttonContainer {
    height: 100% !important;
}
.interfacesContainer {
    position: relative;
    width: 100%;
    height: 35vw;
    max-height: 34em;
    @media  (max-width: 767px) {
        height: 80vw
    }
}
.interfaces { 
    width: auto;
    height: 100%;
}